import PropTypes from 'prop-types';
import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import classnames from 'classnames';

const alignmentToBootStrapClass = (alignment) => {
    if (alignment === 'left') {
        return 'justify-content-start';
    }
    if (alignment === 'right') {
        return 'justify-content-end';
    }
    return 'justify-content-center';
};

const Share = ({
    twitterHandle = '',
    url,
    tags,
    buttons = [],
    border = false,
    horizontalAlignment,
    inline,
    className,
}) => {
    const buttonClassName = classnames({
        "rounded-circle border border-1 border-secondary p-2 btn btn-outline-secondary d-flex justify-content-center align-items-center": border
    }, 'text-center');

    const buttonOptions = {
        twitter: <li className="list-inline-item" key="twitter">
            <TwitterShareButton
                url={url}
                via={twitterHandle.split('@').join('')}
                hashtags={tags}
                className={buttonClassName}
            >
                <FaTwitter className="social-link-icon" />
            </TwitterShareButton>
        </li>,
        facebook: <li className="list-inline-item" key="facebook">
            <FacebookShareButton
                url={url}
                hashtags={tags}
                className={buttonClassName}
            >
                <FaFacebookF className="social-link-icon" />
            </FacebookShareButton>
        </li>,
        linkedin: <li className="list-inline-item" key="linkedin">
            <LinkedinShareButton
                url={url}
                hashtags={tags}
                className={buttonClassName}
            >
                <FaLinkedinIn className="social-link-icon" />
            </LinkedinShareButton>
        </li>
    };
    return <div className={classnames('share-component d-flex align-items-center py-1',
        `${alignmentToBootStrapClass(horizontalAlignment)}`,
        className,
        { inline })}>
        <i title="Share" className="fas fa-share-alt mt-0 me-3" />
        <div className={`list-inline ps-0`}>
            {buttons.map(button => buttonOptions[button])}
        </div>
    </div>;
};

Share.propTypes = {
    tags: PropTypes.array,
    buttons: PropTypes.array,
    twitterHandle: PropTypes.string,
    url: PropTypes.string,
    border: PropTypes.bool,
    inline: PropTypes.bool,
    horizontalAlignment: PropTypes.string,
    className: PropTypes.string
};

Share.defaultProps = {
    tags: [],
    buttons: [],
    twitterHandle: '',
    url: '',
    border: false,
    inline: false,
    horizontalAlignment: 'start'
};

export default Share;