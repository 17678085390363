import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

// components
import Banner from '../../components/banner';
import Layout from '../../components/layout';
import Footer from '../../components/footer';
import HelmetEx from '../../components/helmet-ex';
import Share from '../../components/share';
import CareerPerks from './_career-perks';
import Button from '../../components/button';
import HeadingTitle from '../../components/heading-title';
import config from '../../config';

const RenderApplyNow = (careerDetails) => {
    return careerDetails.jobApplicationUrl && <section className="py-0 p-0">
        <div className="container">
            <div className="row">
                <div className="col mb-0 mt-5 text-end">
                    <Button
                        href={careerDetails.jobApplicationUrl}
                        target="blank"
                        className="btn-primary"
                        type="link"
                        size="large"
                    >
                        Apply Now
                    </Button>
                    <hr className="text-primary" />
                </div>
            </div>
        </div>
    </section>;
};

const CareerDetails = (props) => {

    const { career } = props.pageContext;
    const tagsToString = (tags = []) => {
        if (!tags || tags.length === 0) return '';
        return tags.reduce((acc, value) => acc ? `${acc}, ${value}` : value, '');
    };

    const render = (data) => {
        const { twitterHandle } = data.site.siteMetadata;

        return <Layout>
            <HelmetEx>
                <div metadata="title">Polymaths {career.title ? `| ${career.title}` : ''}</div>
                <div metadata="keywords">{career.title ?? ''}, Polymaths</div>
                <div metadata="description">Polymaths Careers {career.summary ?? ''}</div>
            </HelmetEx>
            <Banner name={`Open Position: ${career.title}`} />
            <div className="career-details-page">
                {RenderApplyNow(career)}
                <>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-5">
                                    <HeadingTitle align="start">
                                        {career.title ?? '...'}
                                    </HeadingTitle>
                                </div>
                                <div className="col-6 col-sm-3 col-md-2 col-lg-2 mb-3">
                                    <div className="d-inline-block">
                                        <h4 className="h5">Location</h4>
                                        <h4 className="h6">{career?.countries?.length > 0 ? tagsToString(career.countries) : 'N/A'}</h4>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-3 col-md-2 col-lg-2 mb-3">
                                    <div className="d-inline-block">
                                        <h4 className="h5">Job Type</h4>
                                        <h4 className="h6">{career?.type?.length > 0 ? tagsToString(career.type) : 'N/A'}</h4>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="d-flex flex-row align-items-center justify-content-md-end mb-3">
                                        <div className="mt-3">
                                            <Share
                                                tags={['polymaths', 'careers', 'open positions', career.title]}
                                                buttons={config.SocialMediaButtons}
                                                url={`${process.env.GATSBY_PLWEB_WEB_ADDRESS}/careers/${career.id}`}
                                                twitterHandle={twitterHandle}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pt-4">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <p>{career.summary}</p>
                                </div>
                            </div>
                            <hr className="tex-primary" />
                            <div className="row">
                                <div className="col mt-5">
                                    <div className="col rich-content" dangerouslySetInnerHTML={{ __html: career.description }} />
                                    {career?.skills?.length > 0 && <div className="col mt-5 p-0">
                                        <h4 className="h5">Required Skills</h4>
                                        <ul>
                                            {career.skills.map((skill, index) => <li key={index}>{skill.name}{skill.proficiencyLevel ? ` - ${_.capitalize(skill.proficiencyLevel)} ` : ''}</li>)}
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                {RenderApplyNow(career)}
                <section>
                    <CareerPerks />
                </section>
                {RenderApplyNow(career)}
            </div>
            <Footer />
        </Layout>;
    };

    return <StaticQuery
        query={graphql`
            query {
                    site {
                    siteMetadata {
                        title
                        twitterHandle
                    }
                }
            }
        `}
        render={render}
    />;
};

CareerDetails.propTypes = {
    id: PropTypes.string,
    navigate: PropTypes.func,
    pageContext: PropTypes.object
};

CareerDetails.defaultProps = {
    career: {}
};

export default CareerDetails;