import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const HeadingTitle = ({
    children,
    className,
    align,
    color,
    level
}) => {
    if (level === 'h3') {
        return <div className={classnames('title', className, {
            [`text-${align}`]: align
        })}>
            <h3 className={classnames('position-relative', {
                [`text-${color}`]: color
            })}>{children}</h3>
        </div>
    }
    return <div className={classnames('title', className, {
        [`text-${align}`]: align
    })}>
        <h2 className={classnames('position-relative', {
            [`text-${color}`]: color
        })}>{children}</h2>
    </div>
};

HeadingTitle.propTypes = {
    align: PropTypes.string,
    className: PropTypes.string,
    align: PropTypes.string,
    color: PropTypes.string,
    level: PropTypes.string
}

HeadingTitle.defaultProps = {
    align: 'center',
    level: 'h3'
}

export default HeadingTitle;
