import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PolymathLink from './polymathlink';

const Button = ({ children, className, size, type, to, href, lighten, anchorClass, ...props }) => {
    if (type === 'polymath-link') {
        return <span className={classnames('button', anchorClass, size, {
            'lighten': lighten
        })}>
            <PolymathLink to={to || href} className={classnames('btn', className)} {...props}>
                {children}
            </PolymathLink>
        </span>;
    }
    if (type === 'link') {
        return <span className={classnames('button', anchorClass, size, {
            'lighten': lighten
        })}>
            <a href={href || to} className={classnames('btn', className)} {...props}>
                {children}
            </a>
        </span>;
    };
    return <span className={classnames('button', size, anchorClass, {
        'lighten': lighten
    })}>
        <button type={type} className={classnames('btn', className)} {...props}>
            {children}
        </button>
    </span>;
};

Button.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    to: PropTypes.string,
    href: PropTypes.string,
    lighten: PropTypes.bool,
    anchorClass: PropTypes.string
};

Button.defaultProps = {
    type: 'button',
    lighten: false,
    anchorClass: ''
};

export default Button;
