import React from 'react';

const CareerPerks = () => {
    return <div className="careers-career-perks container">
        <div className="row">
            <div className="col-12">
                <h2>Polymath perks</h2>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <ul className="ml-0">
                            <li><p>Health benefits.<sup>1</sup></p></li>
                            <li><p>Paid time off.</p></li>
                            <li><p>Office hours.</p></li>
                            <li><p>Work from anywhere.<sup>2</sup></p></li>
                            <li><p>Karma points that offers rewards for remarkable work.</p></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6">
                        <ul className="ml-0">
                            <li><p>Performance bonuses.</p></li>
                            <li><p>Half-day Fridays during summer.</p></li>
                            <li><p>Free snacks (and caffeine, obviously).<sup>1</sup></p></li>
                            <li><p>Internal communities, social activities, and bi-weekly KX sessions.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 text-muted mt-2">
                <ol className="footnotes">
                    <li>Qualified countries only, currently only USA and Kosovo.</li>
                    <li>Remote positions only.</li>
                </ol>
            </div>
        </div>
    </div>;
};

export default CareerPerks;